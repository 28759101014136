<template>
  <div class="page__news-add">
    <y-form
      style="width: 100%"
      label-width="100px"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: '确定',
        resetText: '取消'
      }"
      @submit="submit"
      @reset="reset"
    ></y-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'

export default {
  name: 'BannerAdd',
  data() {
    return {
      model: {
        file: null
      },
      fields: [
        {
          formItemProps: {
            style: 'width: 460px',
            label: '图片',
            prop: 'file'
          },
          render(h, prop, field, model) {
            return <Upload v-model={model[prop]}></Upload>
          }
        }
      ],
      rules: {
        file: [{ required: true, message: '请上传图片', trigger: 'change' }]
      }
    }
  },
  computed: {
    cooperationId() {
      return this.$route.params.id
    }
  },
  watch: {
    cooperationId: {
      handler(val) {
        if (val) this.fetchInfo()
      },
      immediate: true
    }
  },
  methods: {
    async fetchInfo() {
      const res = await this.$serve.baseparamInfo({
        aimid: this.cooperationId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model.file = {
        uid: uid(),
        status: 'success',
        url: res.paramValue
      }
    },
    async submit({ file, ...data }) {
      if (file?.response?.data || file?.url) {
        data.paramValue = file.response?.data || file.url
      }
      if (this.cooperationId) {
        data.id = this.cooperationId
        this.update(data)
      } else {
        this.add(data)
      }
    },
    async add(data) {
      const res = await this.$serve.addCompanyLogo({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('合作企业图片添加成功')
      this.$router.push('/cooperation')
    },
    async update(data) {
      const res = await this.$serve.baseparamUpdate({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('合作企业图片修改成功')
      this.$router.push('/cooperation')
    },
    reset() {
      this.$router.push('/cooperation')
    }
  }
}
</script>

<style lang="scss" scoped>
.page__news-add {
  ::v-deep .upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
}
</style>
